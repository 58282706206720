/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Container, TitleBar } from './styles';
import { AiFillCode, AiFillSetting, AiFillFormatPainter } from 'react-icons/ai';
import ObjectSetting from 'components/Icons/ObjectSetting';

import LogoImg from 'assets/logo-vistei.png';
import HeaderImage from 'assets/header-image.png';
import ManLaptopImage from 'assets/man-laptop.png';
import WomanSmartphoneImage from 'assets/woman-smartphone.png';
import SmartphoneAppImage from 'assets/smartphone-app.png';

const whatsAppLinkg =
  'https://api.whatsapp.com/send?phone=5534998415075&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20plataforma%20Vistei.';

function LandingPage() {
  return (
    <Container>
      <header>
        <nav>
          <img src={LogoImg} className="header-logo" />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button onClick={() => window.open('https://docs.vistei.app')}>Documentação API</button>
          </div>
        </nav>
        <main>
          <div className="box">
            <h1 className="header-title">
              Descomplicando as <br />
              <span style={{ color: '#04D8FE', fontWeight: 900, fontSize: 60 }}>suas vistorias</span>.
            </h1>
            <p className="header-subtitle">
              Com a Vistei você automatiza os seus processos de vistoria e tem mais tempo pra focar no que importa: o
              seu negócio.
            </p>
          </div>
        </main>
        <div className="header-bg" />
      </header>

      <section id="first">
        <main>
          <div className="box center-content ">
            <img src={ManLaptopImage} />
          </div>
          <div className="box center-content text-box">
            <TitleBar />
            <h2>Descomplique suas vistorias </h2>
            <p>Nossas ferramentas foram pensadas para facilitar ao máximo os seus processos de vistoria.</p>
            <div>
              <div className="icon-text">
                <AiFillSetting size={18} color="#004dac" style={{ marginRight: 14 }} />
                <div>
                  <h3>Configure</h3>
                  <span>
                    Com poucos cliques você configura a ferramenta para atender às necessidades do seu negócio.
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <AiFillFormatPainter size={18} color="#004dac" style={{ marginRight: 14 }} />
                <div>
                  <h3>Personalize</h3>
                  <span>
                    Escolha as suas cores e envie o seu logotipo, tudo para deixar a platforma com a sua cara.
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <AiFillCode size={18} color="#004dac" style={{ marginRight: 14 }} />
                <div>
                  <h3>Integre</h3>
                  <span>
                    Com poucas linhas de código, você conta com todas as funcionalidade da plataforma integradas ao seu
                    sistema.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>

      <section id="second" style={{ backgroundColor: '#fff' }}>
        <main className="vertical-main">
          <TitleBar width="170px" />
          <h2>Feita para simplificar</h2>
          <p>
            Nosso objetivo é tornar as suas vistorias muito mais simples e descomplicadas. Com a Vistei, em 3 passos
            simples você configura a ferramenta.
          </p>
          <div className="horizontal-box">
            <div className="card-box">
              <ObjectSetting size={60} style={{ marginBottom: 15 }} />
              <h3>Configure os objetos</h3>
              <p>
                Primeiro, você escolhe o que deseja vistoriar. Pode ser um veículo, um imóvel, um smartphone ou qualquer
                outro objeto que você desejar.
              </p>
            </div>
            <div className="card-box">
              <ObjectSetting size={60} style={{ marginBottom: 15 }} />
              <h3>Configure as fotos</h3>
              <p>
                Depois, você escolhe quais fotos serão enviadas na sua vistoria. Aqui você tem toda a flexibilidade de
                escolher quais e quantas fotos poderão ser enviadas.
              </p>
            </div>
            <div className="card-box">
              <ObjectSetting size={60} style={{ marginBottom: 15 }} />
              <h3>Configure as vistorias</h3>
              <p>
                Por fim, você cria quantas vistorias desejar e escolhe quais fotos estarão disponíveis em cada uma.
                Prontinho! Você já pode começar a vistoriar.
              </p>
            </div>
          </div>
        </main>
      </section>

      <div className="cta-box">
        <div className="box">
          <h3>
            Quer a Vistei <br />
            <span style={{ color: '#04D8FE' }}>na sua empresa</span>?
          </h3>
        </div>
        <div className="box center-content">
          <button onClick={() => window.open(whatsAppLinkg)} style={{ width: '70%', height: 50, fontSize: 20 }}>
            Fale com um especialista!
          </button>
        </div>
      </div>

      <section id="third">
        <main>
          <div className="box center-content text-box">
            <div className="box center-content show-mobile">
              <img src={WomanSmartphoneImage} />
            </div>
            <TitleBar width="170px" />
            <h2>
              Sem barreiras, <br />
              sem complicações!
            </h2>
            <p>
              Com a Vistei, suas vistorias podem ser feitas pelo seu time de vendas, por um vistoriador ou até mesmo
              pelo seu cliente.
            </p>
            <p>Basta gerar um link e compartilhar com qualquer smartphone conectado à internet.</p>
          </div>
          <div className="box center-content hide-mobile">
            <img src={WomanSmartphoneImage} />
          </div>
        </main>
      </section>

      <section id="fourth">
        <main>
          <div className="box center-content">
            <img src={SmartphoneAppImage} />
          </div>
          <div className="box center-content text-box">
            <TitleBar width="210px" />
            <h2>
              Sua segurança é <br /> a nossa maior prioridade
            </h2>
            <p>
              Durante todo o processo de vistoria, coletamos e analisamos vária informações importantes e sempre que
              identificamos alguma atividade suspeita notificamos você e sua equipe no relatório de vistoria.
            </p>
          </div>
        </main>
      </section>

      <footer>
        <main className="footer-section">
          <div>
            <img src={LogoImg} className="footer-logo" />
          </div>
          <div>
            <p>Quer a Vistei na sua empresa?</p>
            <a href={whatsAppLinkg} target="_blank" rel="noreferrer">
              Fale com um especialista!
            </a>
          </div>
          <div>
            <p>Fale conosco</p>
            <a href="tel:34998415075">+55 34 99841-5075</a>
            <p>contato@vistei.app</p>
          </div>
        </main>
      </footer>
    </Container>
  );
}

export default LandingPage;
