import * as React from 'react';

const ObjectSetting = ({ color = '#04D8FE', size, style }) => (
  <div style={{ width: size, height: size, ...style }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      style={{
        enableBackground: 'new 0 0 512 512',
      }}
      xmlSpace="preserve"
    >
      <g fill={color}>
        <path
          d="m123.956 308.453 116.723 64.846c4.797 2.666 10.059 3.998 15.321 3.998s10.525-1.333 15.321-3.998l116.723-64.846a31.566 31.566 0 0 0 16.227-27.579V144.942a31.564 31.564 0 0 0-16.227-27.578L271.321 52.518c-9.594-5.33-21.049-5.33-30.643 0l-116.722 64.846a31.562 31.562 0 0 0-16.228 27.578v135.932a31.57 31.57 0 0 0 16.228 27.579zm265.315-27.579a16.559 16.559 0 0 1-8.512 14.466l-116.723 64.846a16.448 16.448 0 0 1-16.073 0L131.241 295.34a16.559 16.559 0 0 1-8.512-14.466V144.942c0-.46.025-.916.063-1.369L248.5 213.411v119.238a7.5 7.5 0 0 0 15 0V213.411l125.708-69.838c.038.453.063.909.063 1.369zM247.964 65.63c2.516-1.398 5.276-2.097 8.036-2.097s5.521.699 8.036 2.097l116.723 64.846c.187.104.365.218.546.328L256 200.419l-125.306-69.614c.182-.11.36-.225.546-.328zM434.982 225.343a7.5 7.5 0 0 0-6.534 13.502C472.655 260.235 497 288.033 497 317.118c0 28.269-22.854 49.883-42.027 63.037a7.5 7.5 0 0 0 4.25 13.685 7.463 7.463 0 0 0 4.236-1.316C495.215 370.737 512 344.662 512 317.118c0-35.15-27.352-67.743-77.018-91.775z"
          data-original="#000000"
        />
        <path
          d="m424.602 399.976-77.455-7.209a7.5 7.5 0 0 0-1.391 14.935l41.046 3.821C347.847 423.54 303.122 429.86 256 429.86c-65.268 0-126.512-12.241-172.449-34.469C39.345 374.001 15 346.203 15 317.118c0-28.928 24.122-56.617 67.921-77.967a7.5 7.5 0 0 0-6.572-13.484C27.114 249.667 0 282.144 0 317.118c0 35.15 27.352 67.744 77.017 91.775C124.951 432.087 188.515 444.86 256 444.86c47.031 0 91.867-6.119 131.32-17.803l-28.884 23.062a7.5 7.5 0 0 0 4.685 13.362 7.466 7.466 0 0 0 4.675-1.64l60.791-48.536a7.5 7.5 0 0 0-3.985-13.329z"
          data-original="#000000"
        />
      </g>
    </svg>
  </div>
);

export default ObjectSetting;
