import styled from 'styled-components';
import HeaderBg from 'assets/header-bg.jpg';
import HeaderBgMobile from 'assets/header-bg-mobile.svg';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #f6f9fe;
  font-family: 'Roboto', sans-serif;
  padding: 0px;

  header {
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url(${HeaderBg}); */
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #0b4eae;
    color: #fff;
    padding: 0px;
  }

  .header-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${HeaderBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #0b4eae;
    margin: 0px;
    opacity: 0.4;
  }

  section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  main {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 9;
  }

  .vertical-main {
    flex-direction: column;
    align-items: center;
  }

  nav {
    width: 80%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    z-index: 9;
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background: linear-gradient(90deg, rgba(0, 77, 172, 1) 0%, rgba(10, 100, 213, 1) 100%);

    .footer-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #fff;

      div {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      div:nth-child(2n) {
        align-items: center;
        font-size: 17px;
        font-weight: 300;
      }

      a {
        font-weight: 400;
        text-decoration: none;
        color: #04d8fe;
        transition: all ease-in-out 0.3s;
        &:hover {
          opacity: 0.5;
        }
      }

      div:nth-child(3n) {
        align-items: flex-end;
        font-size: 13px;
      }
    }
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #0589f4;
    margin: 0px;
    border: 0px;
    border-radius: 50px;
    padding: 0px 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;
  }

  .center-content {
    align-items: center;
    justify-content: center;
  }

  .text-box {
    padding: 40px;
    align-items: flex-start;
  }

  #first {
    h2 {
      color: #2c3e50;
      font-weight: 300;
      font-size: 32px;
      margin-bottom: 10px;
    }

    h3 {
      color: #004dac;
      font-weight: 400;
    }

    p {
      color: #566d85;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 300;
    }

    span {
      color: #566d85;
      font-size: 18px;
      font-weight: 300;
    }

    img {
      height: 500px;
      transition: transform 0.25s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  #second {
    padding-top: 60px;
    padding-bottom: 90px;

    h2 {
      color: #2c3e50;
      font-weight: 300;
      font-size: 32px;
      margin-bottom: 15px;
    }

    p {
      color: #8699ad;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 300;
      width: 60%;
      text-align: center;
      margin-bottom: 45px;
    }
  }

  #third {
    margin-top: -100px;
    h2 {
      font-weight: 300;
      color: #2c3e50;
      font-size: 32px;
      margin-bottom: 10px;
    }

    p {
      color: #566d85;
      margin-bottom: 10px;
      font-size: 18px;
      text-align: justify;
      font-weight: 300;
    }

    span {
      color: #adadad;
      font-size: 16px;
      font-weight: 400;
    }

    img {
      height: 70%;
      z-index: 999;
      transition: transform 0.25s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  #fourth {
    margin-top: -180px;
    padding-bottom: 60px;
    background-color: #fff;

    h2 {
      color: #2c3e50;
      font-weight: 300;
      font-size: 32px;
      margin-bottom: 10px;
    }

    p {
      color: #8699ad;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 300;
      text-align: justify;
    }

    span {
      color: #adadad;
      font-size: 16px;
      font-weight: 400;
    }

    img {
      height: 70%;
      transition: transform 0.25s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  #fifth {
    padding-top: 60px;
    padding-bottom: 90px;
    h2 {
      font-weight: 300;
      color: #2c3e50;
      font-size: 32px;
      margin-bottom: 0px;
    }
    div {
      margin: 40px 0px;
    }
    video {
      height: 350px;
    }
  }

  .card-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px 0px;
    border-radius: 10px;
    transition: all ease-in-out 0.4s;
    border-top: 10px solid #0a64d5;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    h3 {
      color: #0a64d5;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px !important;
      margin: 0px !important;
      width: 90% !important;
      font-weight: 300 !important;
    }

    &:hover {
      border-top: 10px solid #04d8fe;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  .header-title {
    font-size: 48px;
    font-weight: 700;
    margin-top: 140px;
    margin-bottom: 25px;
    width: 90%;
  }

  .header-subtitle {
    font-size: 20px;
    font-weight: 3;
    font-weight: 300;
    color: '#E4E1FF';
    width: 80%;
    font-family: 'Roboto', sans-serif;
  }

  .header-image {
    margin: 0;
    transition: transform 0.25s;
    &:hover {
      transform: scale(1.05);
    }
  }

  .icon-text {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: top;

    div {
      width: 80%;
    }
  }

  .horizontal-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;
    width: 80%;
  }

  .cta-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    height: 140px;
    padding: 10px 50px;
    background: linear-gradient(90deg, rgba(0, 77, 172, 1) 0%, rgba(10, 100, 213, 1) 100%);
    margin-top: -70px;
    z-index: 999;

    h3 {
      font-size: 30px;
      color: #fff;
      padding-left: 35px;
    }
  }

  .header-logo {
    height: 30px;
    width: auto;
    transition: transform 0.25s;
    &:hover {
      transform: scale(1.05);
    }
  }

  .footer-logo {
    height: 25px;
    width: auto;
    transition: transform 0.25s;
    &:hover {
      transform: scale(1.05);
    }
  }

  .first-icon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  .show-mobile {
    display: none;
  }

  /* ########## RESPONSIBLE ########## */

  @media (max-width: 1200px) {
    header {
      background-image: url(${HeaderBgMobile});
      /* background: linear-gradient(90deg, rgba(0, 77, 172, 1) 0%, rgba(10, 100, 213, 1) 100%); */
      background-size: cover;
      height: 60vh !important;
      justify-content: flex-start;
      padding-bottom: 50px;
      main {
        padding: 40px 10px !important;
        justify-content: center;
        align-items: center;
      }
    }

    main {
      width: 95%;
      display: flex;
      flex-direction: column;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .mobile-hide {
      display: none;
    }

    .header-title {
      font-size: 34px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      margin-top: 15px;
    }

    .header-subtitle {
      font-size: 16px;
      color: '#E4E1FF';
      width: 100%;
      /* text-align: center;   */
    }

    .header-image {
      display: none;
    }

    .text-box {
      padding: 20px;
      margin-top: -25px;
      width: 90%;
    }

    .horizontal-box {
      flex-direction: column;
      padding-bottom: 50px;
      align-items: center;
      width: 100%;
    }

    .card-box {
      margin-bottom: 20px;
    }

    .cta-box {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -60px;
      margin-bottom: 50px;
      height: auto;
      width: 90%;
      padding: 15px 0px;
      border-radius: 10px;

      h3 {
        font-size: 24px;
        color: #fff;
        text-align: center;
        padding: 0px;
        margin: 0px;
        margin-bottom: 15px;
      }
      button {
        height: 36px !important;
        width: 90% !important;
        font-size: 16px !important;
      }
    }

    .hide-mobile {
      display: none;
    }

    .center-content {
      align-items: center;
      justify-content: center;
      h2 {
        width: 70%;
        text-align: center !important;
      }
      p {
        width: 70%;
        text-align: center !important;
      }
    }

    #first {
      img {
        height: 300px;
        transition: transform 0.25s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    #third {
      flex-direction: column-reverse !important;
      margin-top: -30px;
      img {
        width: 300px;
        height: auto;
        transition: transform 0.25s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    #fourth {
      margin-top: 0px;
      img {
        width: 300px;
        height: auto;
        transition: transform 0.25s;
        margin-bottom: 30px;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    #fifth {
      video {
        width: 100%;
        height: auto;
      }
    }

    footer {
      height: auto;
      padding: 25px 10px;
      padding-bottom: 35px;

      .footer-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;

        div {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 10px 0px;
        }

        div:nth-child(2n) {
          align-items: center;
          font-size: 16px;
          font-weight: 300;
        }

        div:nth-child(3n) {
          align-items: center;
          font-size: 16px;
        }
      }
    }
  }
`;

export const TitleBar = styled.div`
  background: linear-gradient(90deg, rgba(5, 137, 244, 1) 0%, rgba(4, 216, 254, 1) 100%);
  width: ${(props) => props.width || '200px'};
  height: 3px;
  margin-bottom: 15px;
`;
